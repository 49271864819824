import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import './assets/css/global.css'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-3NB6E5C6MF" }
}, router)

Vue.use(VueMeta)

Vue.mixin({
  methods: {
    getAssetImg: function (pic) {
      return 'https://res.cloudinary.com/hg53ue8iz/image/upload/' + pic
    },
  },
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
