<template>
  <v-app-bar app dark :src="getAssetImg('/assets/header_ndk0xg.webp')">
    <v-spacer></v-spacer>
    <v-app-bar-title>
      <span class="font-weight-black text-h4">Premium Quality Drywall</span>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <template v-slot:extension>
      <v-tabs centered>
        <v-tab :to="{name: 'home'}" class="font-weight-bold">Home</v-tab>
        <v-tab :to="{name: 'about'}" class="font-weight-black">About Us</v-tab>
        <v-tab :to="{name: 'services'}" class="font-weight-bold">Services</v-tab>
        <v-tab :to="{name: 'licenses'}" class="font-weight-bold">Licenses</v-tab>
        <v-tab :to="{name: 'gallery'}" class="font-weight-bold">Gallery</v-tab>
        <v-tab :to="{name: 'contact'}" class="font-weight-bold">Contact Us</v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  components: {},
  name: "NavBarFull",
};
</script>

<style scoped>
#logoContainer {
  width: 100px;
}
.headline {
  cursor: pointer;
}
</style>
