<template>
  <v-app id="app">
    <NavBar></NavBar>
    <v-main>
      <router-view id="main"/>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>

import AppFooter from "@/components/Footer";
import NavBar from "@/components/navbar/NavBar.vue";

export default {
  name: "App",
  components: {NavBar, AppFooter},
  data() {
    return {}
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Premium Quality Drywall",
    // all titles will be injected into this template
    titleTemplate: "%s | Premium Quality Drywall",
    meta: [
      {charset: "utf-8"},
      {
        name: "description",
        content: "Premium Quality Drywall, San Fernando Valley",
      },
      {name: "viewport", content: "width=device-width, initial-scale=1"},
    ],
  },
};
</script>

<style>
#app {
  background-color: whitesmoke;
}
</style>