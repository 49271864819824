<template>
  <nav-bar-collapsed v-if="this.$vuetify.breakpoint.smAndDown"></nav-bar-collapsed>
  <nav-bar-full v-else></nav-bar-full>
</template>

<script>
import NavBarCollapsed from './NavBarCollapsed.vue';
import NavBarFull from './NavBarFull.vue';

export default {
  components: {NavBarCollapsed, NavBarFull},
  name: "NavBar",
};
</script>

<style scoped>

</style>
