<template>
  <div>
    <v-app-bar app dark :src="getAssetImg('/assets/header_ndk0xg.webp')">
      <span
          class="headline"
          @click="$router.push({ name: 'home' }).catch((err) => {})"
      >
        Premium Quality Drywall
      </span>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app right>
      <v-list nav>
        <v-list-item @click.stop="drawer = !drawer">
          <v-list-item-title align="right">
            <v-icon color="red"> mdi-close</v-icon>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'home' }">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'about' }">
          <v-list-item-title>About Us</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'services' }">
          <v-list-item-title>Services</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'licenses' }">
          <v-list-item-title>Licenses</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'gallery' }">
          <v-list-item-title>Gallery</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'contact' }">
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      drawer: false,
    };
  },
  name: "NavBarCollapsed",
};
</script>

<style scoped>
#logoContainer {
  width: 100px;
}

.headline {
  cursor: pointer;
}
</style>
