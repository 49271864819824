<template>
  <v-footer class="mt-16 mb-8" color="#F5F5F5">
    <v-row>
      <v-col v-for="item in items" :key="item.header" cols="12" md="4">
        <v-card min-height="100%" class="d-flex align-center justify-center">
          <v-container>
            <v-row align="center">
              <v-col cols="4" align="center">
                <v-avatar color="grey darken-2">
                  <v-icon dark color="white" large>
                    {{ item.icon }}
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="7">
                <span class="font-weight-bold"> {{ item.header }}</span>
                <br/>
                <span> {{ item.subTitle }}</span>
                <template v-if="item.subTitle2">
                  <br/>
                  <span>{{ item.subTitle2 }}</span>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      items: [
        {
          icon: 'mdi-phone-classic',
          header: 'Free Estimates',
          subTitle: 'Call today to get your free estimate!',
          subTitle2: '(818) 429-2924',
        },
        {
          icon: 'mdi-briefcase-check',
          header: 'The Experience You Need',
          subTitle: 'With over 30 years of experience, you can be sure the job is done right the first time.',
        },
        {
          icon: 'mdi-account-hard-hat',
          header: 'The Service You Expect',
          subTitle: 'Reliable, quality service for all your home remodel and restoration needs.',
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>